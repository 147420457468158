import React, { useEffect, useState } from 'react';
import child from '../images/landing-img.png';
import "../pages/styles/landing.css";
import { Modal,Form} from 'antd';
import "antd/dist/antd.css";
import { useTranslation } from 'react-i18next';
import i18next from 'i18next';
import { useSelector } from 'react-redux';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { setUser } from "../store/actions/userActions";
import { useDispatch } from "react-redux";
import Swal from 'sweetalert2';
import { useHistory } from "react-router-dom";
import {Loader} from '../components/loader.jsx';
import axios from "axios";


export const LandingSection = () => {

    const dispatch = useDispatch();
    const history = useHistory();
    const [logIn, setlogIn] = useState('none');
    const [userDetails, setUserDetails] = useState({ casenumber: '', password: '' });    
    const [isPending, setPending] = useState(false);
    const [isRequesting, setRequesting] = useState(false);
    const[passwordResetData,setPasswordResetData] = useState({caseNumber:''})

    //get form data
    const caseFormHandler = (e) => {
        e.preventDefault();
        setUserDetails({ ...userDetails, [e.target.name]: e.target.value });
    };


    const passwordResetHandler = (e) => {
        e.preventDefault();
        setPasswordResetData({ ...passwordResetData, [e.target.name]: e.target.value });
    };


    //form errors
    const [casNumberError, setCaseError] = useState('');
    const [passwordError, setPasswordError] = useState('');

    const swalAlert = (text,icon,color)=>{
         Swal.fire({
                width: 400,
                height: 5,
                showConfirmButton: true,
                showCancelButton: false,
                closeOnConfirm: true,
                closeOnCancel: true,
                confirmButtonText: 'OK',
                confirmButtonColor: '#ea9b0a',
                confirmButtonBorderColor: 'none',
                cancelButtonText: 'Cancel',
                text: text,
                icon: icon,
                iconColor:color,
                iconHeight: 5,
                confirmButtonText: 'close'
            });
    }

    const swalReloadAlert = (text,icon,color)=>{
        Swal.fire({
               width: 600,
               height: 5,
               showConfirmButton: true,
               showCancelButton: false,
               closeOnConfirm: true,
               closeOnCancel: true,
               confirmButtonText: 'OK',
               confirmButtonColor: '#ea9b0a',
               confirmButtonBorderColor: 'none',
               cancelButtonText: 'Cancel',
               text: text,
               icon: icon,
               iconColor:color,
               iconHeight: 5,
               confirmButtonText: 'close'
           }).then(function () {
            window.location.reload();
          });;
   }


    //api call for log user
    const getStatus = () => {
       
        if (userDetails.casenumber === "") {
            setCaseError('case number required');
            return;
        }

        if (userDetails.password === "") {
            setPasswordError('password required');
            return
        }
        setPending(true);
       
        axios.post(process.env.REACT_APP_API_URL+'login', {
            username: userDetails.casenumber,
            password: userDetails.password            
        },
        {
            headers: {
              Authorization: process.env.REACT_APP_API_TOKEN
            }
          }
        ).then(function (response) {
            setPending(false);
            if(response.data.status === true) {                
                dispatch(setUser(userDetails.casenumber));
                history.push('/status');
            } 
            else if(response.data.status === 'Mena')
            {               
                return swalReloadAlert(response.data.error,'info','#ff0000');
            }
            else return swalReloadAlert('incorrect credentials! Please try again','error','#ff0000');
        }).catch(function (error) {          
            return swalReloadAlert(error.response.data.error+'Please try again','error','#ff0000');
           
        });
    }

    //api call for password reset
    const passwordReset = ()=>{
        setRequesting(true);

        axios.post(process.env.REACT_APP_API_URL+'api/url',passwordResetData,
        {
            headers: {
              Authorization: process.env.REACT_APP_API_TOKEN
            }
          }
        ).then(function (response) {
            setRequesting(false);
            if(response.data.status===true) {      
                
                setIsModalVisible(false);
                return swalAlert('request sent!','success','green');

            } else {
                setIsModalVisible(false);
                return swalReloadAlert('incorrect credentials! Please try again','error','#ff0000')};
        }).catch(function (error) {            
            if (error.response.status === 400) {             
                setIsModalVisible(false);
                return swalReloadAlert(error.response.data.message+'!Please try again','error','#ff0000');
              } else {
                setIsModalVisible(false);
                return swalReloadAlert('An error occured! Please try again','error','#ff0000');
              }
           
            
        });
    }

    
    const [isModalVisible, setIsModalVisible] = useState(false);
    const theme = useTheme();
    const matches = useMediaQuery(theme.breakpoints.down('sm'));

    const showModal = () => {
        setIsModalVisible(true);
    };  
    const handleOk = () => {
        setIsModalVisible(false);
    };

    const handleCancel = () => {
        setIsModalVisible(false);
    };

    const language = useSelector(state => state?.language?.language);
    const { t } = useTranslation();

    useEffect(() => {
        i18next.changeLanguage(language)
    }, [language]);

    const formRef = React.useRef();
    

    useEffect(() => {
        let resizeObserverEntries = []
    
        const observer = new ResizeObserver((entries)=>{
            resizeObserverEntries = entries
        })

        if(formRef.current) observer.observe(formRef.current)

        return () => {
            resizeObserverEntries.forEach((entry)=>entry.target.remove())
            observer.disconnect()
        }
    },[]);
    const displayHowToUseSection = (content,title) => {
        return (
            <section className='mt-3 px-5'>
                <h1 style={{ fontWeight: "bold", fontSize:title, }}>{t('how_to_use')}</h1>
                <div style={{ color: "black", fontSize: content, }}>
                    <p>{t('dear_text')}</p>
                    <p>{t('first_paragraph')}</p>
                    <p>{t('second_paragraph')}</p>
                    <p>{t('third_paragraph')}</p>
                    <p>{t('fourth_paragraph')}</p>                   
                </div>
            </section>);
    }

    const displayNoticeSection = (content,title) => {
        return (
            <section className='mt-3 px-5'>
                <h1 style={{ color: "white", fontWeight: "bold", fontSize: "26px", textAlign: "center" }}>Notice</h1>
                <div style={{ color: "white", fontSize: content, }}>
                    <p>{t('notice.paragraph1')}</p>
                    <p>{t('notice.paragraph2')}</p>
                    <p>{t('notice.paragraph3')}</p>
                </div>
            </section>);
    }
    //validators
    const objectValidator = (data)=>{
        for(var key in data) {
        if(data[key] === "") {
          return true
        }
       
    }
    }

//     function validatePhone(phone) {
//   const regex = /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/;
//   return(regex.test(phone))
// }


    // Conditional Rendering for mobile screen, medium screen and large screen
  
    //Render mobile screen
    if (matches) {
        return (
            <>
                <div className="row1-mobile">
                    <div className="header-mobile-image">
                        <img src={child} width='50%' height='50%'  className="bg-mobile-image" />
                    </div>

                    <div className="header-mobile-text">
                        <p>
                            <span className='header-mobile-text1'>MyCase</span>
                            <p><span className='header-mobile-text2'> US Refugee Admissions Program</span></p>
                        </p>
                        <p className='header-mobile-text3'>
                            RSC Africa is operated by CWS as part of a
                            cooperative agreement with the US Department
                            of State
                        </p>
                    </div>
                </div>

        
                <div className="form-mobile">
                    <h1 style={{ fontWeight: "bold", textAlign: "center", fontSize: "26px", color: "white"}}>{t('check_my_case')}</h1>
                    <p style={{ textAlign: "center", fontSize: "16px", color: "white" }}>{t('please_text')}</p>
                    <input type="text" id="cnum" name="casenumber" required onChange={caseFormHandler} placeholder={t('case_number')} style={{ marginBottom: "50px", width: "100%", padding: "15px", textAlign: "center", backgroundColor: "#dbd6d6" }} />
                    {casNumberError && <span style={{ textAlign: "center", fontSize: "14px", color: 'red' }}> {casNumberError}</span>}
                    <input type="password" id="pass" name="password" required onChange={caseFormHandler} placeholder={t('password')} style={{ borderColor: "#dbd6d6", marginBottom: "50px",width: "100%", padding: "15px", textAlign: "center", backgroundColor: "#dbd6d6" }} />
                    {passwordError && <span style={{ textAlign: "center", fontSize: "14px", color: 'red' }}> {passwordError}</span>}
                    <div style={{ textAlign: "center" }}>
                        <button onClick={getStatus} style={{ cursor: "pointer", boxShadow: "none", fontWeight: "bold", backgroundColor: "black", color: "white", borderColor: "black", paddingTop: "15px", paddingBottom: "15px", paddingLeft: "30px", paddingRight: "30px" }}>
                            {isPending ?(<Loader/>):('')}
                            {t('view_case')}
                        </button>
              
                        <p style={{ marginTop: 10,color:"white" }}>{t('dont_remember')}</p>
                        <p style={{ color: "white", fontWeight: "bold", cursor: "pointer" }} onClick={showModal}>{t('request_password')}</p>
              
                       <Modal
                                
                                title={<span style={{ fontWeight: "bold" }}>Password Change Request</span>}
                                visible={isModalVisible}
                                onOk={handleOk}
                                onCancel={handleCancel}
                                footer={null}
                                >
                                   <form ref={formRef} noValidate autoComplete="off">  
                                   <TextField
                                    variant="outlined"
                                    margin="normal"
                                    required
                                    fullWidth

                                    id="rscCaseNumber"
                                    label="RSC Case Number"
                                    name="caseNumber"
                                    onChange={passwordResetHandler }
                                />
                               
                                <Button
                                variant="contained"
                                onClick={() => {if(objectValidator(passwordResetData)===true){formRef.current.reportValidity()} else {passwordReset()}}}
                                color="primary"
                                className='request-btn'
                                >
                                {isRequesting ?(<Loader />):('')}
                                Send Request
                                </Button>
                                </form>
                                
                            </Modal>
                    </div>
                </div>
        
                <div className="row2-mobile">
                    {displayHowToUseSection('14px','26px')}
                </div>
            
                <div className='row3-mobile'>
                {displayNoticeSection('14px','10px')}
                </div>
            </>
        )
    }

    //Otherwise render medium and large screen
    return (
        <>
            <div className="main-content">
                <div className="row1">
                    <div className="header-text">
                        <p><span className='header-text1'>MyCase</span><p>
                            <span className='header-text2'> US Refugee Admissions Program</span></p>
                        </p>
                
                        <p className='header-text3'>
                            RSC Africa is operated by CWS as part of a
                            cooperative agreement with the US Department
                            of State
                        </p>
                    </div>
        
                    <div className="header-image">
                        <img src={child} width='100%'  className="header-bg-image" />
                    </div>
                </div>

                <div className='row2'>
                    <div className='form-section' >
                            
                        <h1 style={{ fontWeight: "bold", textAlign: "center", fontFamily: 'Lato', fontSize: "40px" }}>{t('check_my_case')}</h1>
                        <p style={{ textAlign: "center", fontSize: "16px", color: "black", fontFamily: 'Lato' }}>{t('please_text')}</p>
                        <input type="text" id="cnum" name="casenumber" required onChange={caseFormHandler} placeholder={t('case_number')} style={{ marginBottom: "20px",textAlign: "center", width: "100%", padding: "23px", backgroundColor: "#dbd6d6" }} />
                        {casNumberError && <span style={{ textAlign: "center", fontFamily: 'Lato', fontSize: "14px", color: 'red' }}> {casNumberError}</span>}
                        <input type="password" id="pass" name="password" required onChange={caseFormHandler} placeholder={t('password')} style={{ borderColor: "#dbd6d6", marginBottom: "20px",textAlign: "center", width: "100%", padding: "23px", backgroundColor: "#dbd6d6" }} />
                        {passwordError && <span style={{ textAlign: "center", fontFamily: 'Lato', fontSize: "14px", color: 'red' }}> {passwordError}</span>}
                        <div style={{ textAlign: "center" }}>
                           
                            <button  onClick={getStatus} style={{ cursor: "pointer", boxShadow: "none", fontWeight: "bold", backgroundColor: "black", color: "white", borderColor: "black", paddingTop: "15px", paddingBottom: "15px", paddingLeft: "30px", paddingRight: "30px" }}>
                                 {isPending ?(<Loader />):('')}
                                {t('view_case')}
                                
                            </button>
                                
                            <p style={{ marginTop: 10 }}>{t('dont_remember')}</p>
                            <p style={{ color: "#3f51b5", fontWeight: "bold", cursor: "pointer" }} onClick={showModal}>{t('request_password')}</p>
                                
                            <Modal
                                
                                title={<span style={{ fontWeight: "bold" }}>Password Change Request</span>}
                                visible={isModalVisible}
                                onOk={handleOk}
                                onCancel={handleCancel}
                                footer={null}
                                >
                                   <form ref={formRef} noValidate autoComplete="off">  
                              
                                <TextField

                                    variant="outlined"
                                    margin="normal"
                                    required
                                    fullWidth

                                    id="rscCaseNumber"
                                    label="RSC Case Number"
                                    name="caseNumber"
                                    onChange={passwordResetHandler }
                                />
                                <Button
                                variant="contained"
                                onClick={() => {if(objectValidator(passwordResetData)===true){formRef.current.reportValidity()} else {passwordReset()}}}
                                color="primary"
                                className='request-btn'
                                >
                                {isRequesting ?(<Loader />):('')}
                                Send Request
                                </Button>
                                </form>
                                
                            </Modal>
                        </div>
                    </div>

                    <div className='how-to-use' >
                        {displayHowToUseSection('16px','40px')}
                    </div>
                </div>

                <div className="row3">
                    {displayNoticeSection('16px','40px')}
                </div>
            </div>        
        </>
    )
}
